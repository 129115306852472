<template lang="pug">
section.shop-with-peace.lazy(data-lazy-function="loaded")
  .shop-with-peace__inner
    .content
      .shop-with-peace__title {{ $t('homePage.shopWithPeace.title') }}
      .shop-with-peace__items.is-flex
        .shop-with-peace__item(v-for="item in items", :key="item.id")
          .shop-with-peace__item-icon.is-flex
            icon(:iconName="item.icon", iconColor="#80704d", :width="item.width", :height="item.height")
          .shop-with-peace__item-title {{ item.title }}
          .shop-with-peace__item-text(v-html="item.text")
</template>

<script>
export default {
  name: 'ShopWithPeaceComponent',

  computed: {
    items() {
      return [
        {
          id: 0,
          icon: 'shipping',
          title: this.$t('homePage.shopWithPeace.0.title'),
          text: this.$t('homePage.shopWithPeace.0.text'),
          width: 93,
          height: 41
        },
        {
          id: 1,
          icon: 'return',
          title: this.$t('homePage.shopWithPeace.1.title'),
          text: this.$t('homePage.shopWithPeace.1.text'),
          width: 55,
          height: 55
        },
        {
          id: 2,
          icon: 'warranty',
          title: this.$t('homePage.shopWithPeace.2.title'),
          text: this.$t('homePage.shopWithPeace.2.text'),
          width: 43,
          height: 55
        },
        {
          id: 3,
          icon: 'financing',
          title: this.$t('homePage.shopWithPeace.3.title'),
          text: this.$t('homePage.shopWithPeace.3.text'),
          width: 50,
          height: 41
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
@include background-image('shop-with-peace', 'homePage/shopWithPeace/bg', 'jpg')
.shop-with-peace
  padding: 152px 80px
  margin-top: 220px
  +tablet-only
    padding: 80px 20px
  +mobile
    padding: 60px 15px 124px
  &__inner
    background-color: #ffffff
    width: 100%
    padding: 136px 200px 158px
    max-width: 1753px
    margin: auto
    +until-widescreen
      padding: 60px 20px
    +widescreen-only
      padding: 80px 40px
  &__title
    font-family: $lora
    font-size: 36px
    font-style: italic
    text-align: center
    +mobile
      font-size: 32px
  &__items
    justify-content: space-between
    margin-top: 80px
    column-gap: 20px
    +touch
      margin-top: 40px
    +mobile
      flex-direction: column
      row-gap: 32px
  &__item
    max-width: 248px
    text-align: center
    +mobile
      max-width: unset
  &__item-icon
    height: 60px
    align-items: center
    justify-content: center
  &__item-title
    margin: 30px auto 0
    font-size: 18px
    font-weight: bold
    max-width: 165px
    +mobile
      margin-top: 20px
  &__item-text
    margin-top: 20px
    font-size: 16px
</style>
